import React from 'react'

import LayoutDisconnected from 'components/layout/disconnected'
import PageLogin from 'components/pages/disconnected/login'

export default () => (
  <LayoutDisconnected>
    <PageLogin />
  </LayoutDisconnected>
)
